@import url("https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200;12..96,300;12..96,400;12..96,500;12..96,600;12..96,700;12..96,800&family=DM+Serif+Display&family=Jost:ital,wght@0,400;0,700;1,400&display=swap");
@import "./variables.modules";

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: 'Bricolage Grotesque', Helvetica, sans-serif;
    font-size: 1.5vh;
    width: 100%;
    height: 100%;
    color: $dark;
    background-color: $white;
    box-sizing: border-box;
}

li {
    color: $dark;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a,
a:link,
a:hover,
a:visited,
a:active {
    text-decoration: none;
}

img {
    max-width: 100%;
}

ul {
    list-style-type: none;
}